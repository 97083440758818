<template>
  <div class="mainwrap restauranlist" v-loading="loading">
    <div class="title">
      聯繫我們
    </div>

    <div class="searchform">
      <el-form
          label-width="90px"
          :inline="true"
          :model="searchForm"
          class="demo-form-inline"
      >
        <el-form-item label="姓名：">
          <el-input v-model="searchForm.username" placeholder="請輸入姓名"></el-input>
        </el-form-item>
        <el-form-item label="電話：">
          <el-input v-model="searchForm.phone" placeholder="請輸入電話"></el-input>
        </el-form-item>
        <el-form-item label="郵箱：">
          <el-input v-model="searchForm.email" placeholder="請輸入郵箱"></el-input>
        </el-form-item>
        <el-form-item label="刪除帳號：">
          <el-select v-model="searchForm.applyForDeletion" placeholder="請選擇">
            <!-- <el-option label="請選擇" value=""></el-option> -->
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button @click="reSet('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tablewrap">
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="phoneNumber" label="聯絡電話"> </el-table-column>
        <el-table-column prop="email" label="電子郵箱"> </el-table-column>
        <el-table-column prop="brand" label="品牌"> </el-table-column>
        <el-table-column prop="store" label="分店"> </el-table-column>
        <el-table-column prop="proposal" label="建議事項"> </el-table-column>
        <el-table-column prop="applyForDeletion" label="刪除帳號"> </el-table-column>
        <el-table-column prop="status" label="狀態"> </el-table-column>
        <el-table-column label="操作" width="220" v-if="permissionType === 2">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row)" type="primary" plain>
              编辑
            </el-button>
            <div class="delbtn">
              <el-popconfirm
                  confirm-button-text='確定'
                  cancel-button-text='取消'
                  confirm-button-type="danger"
                  icon="el-icon-info"
                  icon-color="red"
                  title="確定刪除？"
                  @confirm="deleteContactUs(scope.row.id)"
              >
                <el-button type="danger" slot="reference">刪除</el-button>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
          background
          :hide-on-single-page="isSinglePage"
          layout="prev, pager, next,jumper"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :total="tableDataTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from "./api";
import { getStore} from '@/utils/storage';

export default {
  name:'contactUs',
  data(){
    return{
      loading: false,
      searchForm:{
        username:"",
        phone:"",
        email:"",
        sorting:'',
        maxResultCount: 10,
        applyForDeletion:""
      },
      isSinglePage: false,
      currentPage: 1,
      tableData: [],
      tableDataTotal:0,
      permissionType: -1
    }
  },
  methods:{
    // 查詢
    onSearch() {
      this.currentPage=1;
      this.getList();
    },
    reSet(){
      this.searchForm = {
        username:"",
        phone:"",
        email:"",
        sorting:'',
        maxResultCount: 10,
        applyForDeletion:""
      }
      this.currentPage=1;
      this.getList();
    },
    // 翻頁
    handleCurrentChange(val) {
      console.log(val);
      this.currentPage=val;
      this.getList()
    },
    deleteContactUs(id){
      api.contactUsDelete(id).then(res=>{
        if(res.systemCode===200){
          this.$message.success('刪除成功');
          if (this.tableData.length <= 1) {
            this.currentPage = this.currentPage > 1 ? this.currentPage - 1 : 1;
          }
          this.getList();
        }
      })
    },
    getList() {
      this.loading = true;
      api.contactUsList({
        Name: this.searchForm.username,
        Email: this.searchForm.email,
        PhoneNumber: this.searchForm.phone,
        Sorting: this.searchForm.sorting,
        SkipCount: (this.currentPage - 1) * this.searchForm.maxResultCount,
        MaxResultCount: this.searchForm.maxResultCount,
        applyForDeletion:this.searchForm.applyForDeletion
      }).then(res=>{
        if(res.systemCode===200){
          this.tableData = res.data.items
          this.tableDataTotal = res.data.totalCount
        }
        this.loading = false;
      })
    },
    handleEdit(item) {
      this.$router.push({
        path: "/cms/infoedit",
        query: {
          type: "edit",
          id: item.id,
        },
      });
    }
  },
  activated(){
      this.getList();
  },
  created() {
    this.getList()
    this.permissionType = parseInt(getStore("permissionType"));
  }
};
</script>

<style lang="scss" scoped>
.restauranlist {
  .searchform {
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }
  .addbtn {
    width: 150px;
    float: right;
  }
  .delbtn{
    display: inline-block;
    margin-left: 10px;
  }
}
</style>
