import { getRequest,deleteRequest,postRequest } from "@/http/request_service.js";
import { API } from "@/api/api.js"

export const contactUsList=(params)=>{
    return getRequest(API.contactUsList,params);
}

export const contactUsDetail=(params)=>{
    return getRequest(API.contactUsList+'/'+params);
}

export const contactUsDelete=(params)=>{
    return deleteRequest(API.contactUsList+'/'+params);
}

// 編輯聯繫我們
export const contactUsEdit=(params)=>{
    return postRequest(API.contactUsEdit,params);
}

